.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise15 .question {
    text-align: left;
    margin: auto;
    margin: 50px auto 20px auto;
    display: flex;
}

.exercise15 .question span {
    background-color: #e6197f;
    color: white;
    padding: 20px 40px;
    border-radius: 25px;
}

.exercise15 .answer {
    text-align: left;
    margin-top: 8px;
    margin-left: 50px;
    cursor: pointer;
    display: flex;
}

.exercise15 .answer span {
    border: 2px solid #e6197f;
    padding: 10px 30px;
    border-radius: 25px;
    max-width: 90%;
}

.exercise15 .answer.checked span {
    background-color: #ffcc00 !important;
}

.exercise15 .answer.good span {
    background-color: #d0ffd1 !important;
}

.exercise15 .answer.bad span {
    background-color: #ffd0d0 !important;
}

.exercise15 .answer:hover span {
    background-color: #fff7d9;
}

.exercise15 button {
    max-width: 200px;
    max-height: 50px;
    min-height: 50px;
    min-width: 150px;
    width: 100%;
    background-color: #e6197f !important;
    color: #fff !important;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    border: 0px;
    margin: 50px auto 20px auto;
}

.exercise15 .alert {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: #e6197f;
    width: 80%;
    margin: auto;
}

.exercise15 .answer .icon {
    width: 45px;
    height: 45px;
    background-color: white;
    border: 2px solid;
    border-radius: 50px;
    margin-left: 20px;
    color: #e6197f;
}

.exercise15 .answer .icon svg {
    width: 100%;
    height: 100%;
    padding: 5px;
}


@media (max-width: 992px) {   
    .exercise15 .answer .icon {
        position: absolute;
        right: 20px;
    }
}