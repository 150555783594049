.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise16 .alert {
    background-color: #e6197f;
    color: white;
    width: 70%;
    margin:auto;
    border-radius: 40px;
    padding: 30px 40px;
}

.exercise16 .alert span {
    display: block;
    margin: 15px;
}

.exercise16 .alert button {
    cursor: pointer;
    border-radius: 30px;
    padding: 20px 35px;
    margin: 20px 10px 0 10px;
    border: none !important;
    font-weight: bold;
}

.exercise16 .alert button.white {
    color: #e6197f;
    background-color: white !important;
}

.exercise16 .alert button.pink {
    color: white;
    background-color: #e6197f !important;
    border: 2px solid white !important;
}


@media (max-width: 992px) {   
    .exercise16 .alert {
        margin: 0;
        padding: 0;
        width: 100%;
    }
}