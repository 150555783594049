.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise8 img {
    width: 80%;
    margin: auto;
} 

.exercise8 span {
    color: #e6197f;
    font-weight: bold;
}