:root {
    --primary: #ffcc00;
    --secondary: #e6197f;
    --text-primary: #6f6f6e;
    --text-secondary: #ffffff;
    --text-third:#404040;
    --bg-primary: #f6f6f6;
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src:
        local('Gilroy'),
        local('Gilroy-Light'),
        url(/fonts/Gilroy-Light/font.woff2) format('woff2'),
        url(/fonts/Gilroy-Light/font.woff) format('woff');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    src:
        local('Gilroy'),
        local('Gilroy-ExtraBold'),
        url(/fonts/Gilroy-ExtraBold/font.woff2) format('woff2'),
        url(/fonts/Gilroy-ExtraBold/font.woff) format('woff');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src:
        local('Lato'),
        local('Lato-Medium'),
        url(/fonts/Lato/Lato-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: normal;
    src:
        local('Lato'),
        local('Lato-SemiboldItalic'),
        url(/fonts/Lato/Lato-SemiboldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: bold;
    src:
        local('Lato'),
        local('Lato-BoldItalic'),
        url(/fonts/Lato/Lato-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-weight: bold;
    font-style: normal;
    src:
        local('Lato'),
        local('Lato-Black'),
        url(/fonts/Lato/Lato-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-weight: normal;
    font-style: normal;
    src:
        local('Lato'),
        local('Lato-Semibold'),
        url(/fonts/Lato/Lato-Semibold.ttf) format('truetype');
}

html, body {
    height:100%;
    width: 100%;
    background-color: #ffffff;
    font-family: 'Gilroy';

    position: relative;
    -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;

   -ms-user-select: none;
   user-select: none;
   color: #6f6f6e;
   text-align: justify;
}

#root {
    width:100%;
    height:100%;
}

.st0{
    fill: var(--bg-primary) !important;
}

header {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 51px;
    border-bottom: 1px solid #fff6d2;
    color: var(--text-primary) !important;
    font-family: 'Gilroy';
    font-weight: 700;
    z-index: 9999;
}

header #mainNav {
    padding: 0;
    background-color: var(--primary);
}

header .nav-link {
    color: var(--text) !important;
    margin: 0 15px;
    font-size: 18px;
    letter-spacing: 0.6px;
    transition: 0.2s all;
}

header .nav-link:hover {
    color: var(--text-secondary) !important;
    transition: 0.2s all;
}

#wrapper {
    height: calc(100vh - 51px - 35px);
}

#wrapper>.row {
    width: 100%;
    height: 100%;
    margin: 0px;
}

#wrapper>.row>div {
    padding: 0;
}

footer {
    left: 0;
    width: 100%;
    min-height: 35px;  
    background-color: var(--secondary);
    border-top: 1px solid #f6aad0;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: fixed;
    bottom: 0;
    z-index: 1020;
    font-family: 'Lato';
    font-size: 14px;
}

footer b {
    letter-spacing: 1px;
}

footer img {
    max-height: 25px;
    max-width: 25px;
    margin: 0 10px;
}

article>.container {
    padding: 0;
    text-align: justyfy;
}

article u {
    text-decoration: none;
    font-weight: bold;
}

h1 {
    color: var(--text-primary) !important;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 1px;
    margin-top: 50px;
    margin-left: 15px;
}

blockquote, p, ul {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
    color: var(--text-third);
    white-space: break-spaces;
}

blockquote br {
    margin: 5px 0;
    line-height: 6px;
    content: " ";
    display: block;
}

p b {
    color: var(--secondary);
}

blockquote {    
    font-style: italic;
}

blockquote img.begin {
    height: 18px;
    vertical-align: bottom;
}

blockquote img.end {
    height: 18px;
    vertical-align: top;
}

blockquote span.begin, blockquote span.end {
    font-size: 40px;
    color: #e6197f;
    line-height: 9px;
}

blockquote span.end {
    margin-left: 3px;
    margin-top: -12px;
    position: absolute;
}

.knowledge-extend {
    cursor: pointer;
}

.modal .modal-header {
    color: #e6197f;
}


.modal .modal-body p b {
    color: var(--text-third);
}

.modal .modal-footer button {
    background-color: #e6197f;
    border: 0;
    border-radius: 20px;
    padding: 10px 25px;
    margin: auto;   
}

.modal .modal-footer button:active {
    background-color: #ffcc00 !important;
    color: #e6197f !important;
    font-weight: bold;
}

.modal .modal-body p {
    padding: 10px 50px;
    margin: 0;
}

.modal .modal-body {    
    max-height: 600px;
    overflow: auto;
}

.modal .modal-title {
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.btn:hover {
    color: var(--primary) !important;
}


.exercise .title {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

.exercise .subtitle {
    color: #e6197f;
    font-weight: bold;
    padding: 0 30px;
}

.btn-white {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 5px 25px;
    margin: 3px 10px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    padding: 3px 10px;
}

.btn-pink {
    color: #e6197f;
    border: 2px solid #e6197f;
    border-radius: 30px;
    padding: 20px 30px;
    margin: 0 10px;
    width: 80%;
    margin: 0 20px;
    font-weight: bold;
    letter-spacing: 1px;
}

.buttonOrderNext .pink {
    color: var(--secondary);
    font-weight: bold;
}

button.btn-pink2 {
    background-color: var(--secondary);
    font-weight: bold;
    padding-top: 20px;
    color: white !important;
    padding: 10px 50px;
}

img, button, div {    
    outline: none !important;
    box-shadow: none !important;
}

a {
    color: inherit;
}

a.pink {
    color: #e6197f;
    font-weight: bold;
}

a.orange {
    color: #ffcc00;
    font-weight: bold;
}

a:hover {
    color: var(--primary);
    text-decoration: none;
}

.uppercase {
    text-transform: uppercase;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    max-width: 4px;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background-color: var(--primary) !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    background-color: rgba(255, 204, 0, 0.29) !important;
}

.ScrollbarsCustom-Wrapper {
    right: 0 !important;
}

.menu nav {
    margin: 20px 5px;
    padding-right: 10px;
}

.menu nav .item {
    margin-bottom: 20px;
}

.menu nav .item.active {
    color: var(--primary) !important;
}

.menu nav .item.disabled {
    color: #c6c6c5 !important;
}

.menu nav {
    margin: 20px 5px;
}

.menu nav .item {
    margin-bottom: 20px;
}

.menu nav .item.active {
    color: var(--primary) !important;
}

.menu nav .item.disabled {
    color: #c6c6c5 !important;
}

.menu nav h6 {
    font-family: 'Lato';
    font-weight: 100;
    font-size: 15px;
    letter-spacing: 0.3px;
    margin: 0;
}

.menu nav span {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.6px;
}

.menu .item div {
    line-height: 22px;
}

.spinner {
    height: 100px;
    width: 300px;
    position:absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -150px;
    letter-spacing: 3px;
    text-align: center;
}

.bg-primary {
    background-color: var(--bg-primary) !important;
}

.mt--1 {
    margin-top: -30px;
}

.mt--2 {
    margin-top: -60px;
}

.mt--3 {
    margin-top: -90px;
}

.mb--1 {
    margin-bottom: -30px;
}

.mb--2 {
    margin-bottom: -60px;
}

.mb--3 {
    margin-bottom: -90px;
}

.pt-6 {
    padding-top: 4rem!important
}

.pb-6 {
    padding-bottom: 4rem!important
}

.pl-6 {
    padding-left: 5rem !important;
}

ul.upl-6 {
    padding-left: 5rem !important;
}

ul.upl-5 {
    padding-right: 3rem !important;
}

.w-80 {
    width: 80%;  
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.w-30 {
    width: 30%;  
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.w-60 {
    width: 60%;  
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.relative {
    position: relative;
}

.mh-200 {
    min-height: 200px;
}

.underline {
    text-decoration: underline;
}

.article a {
    color: var(--secondary);
    font-weight: bold;
}

.article a:hover {
    color: var(--primary);
    font-weight: bold;
}

.mTable table, .modal .modal-body table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 80%;
}

.mTable  td, .mTable th, .modal .modal-body table td, .modal .modal-body table th {
    border: 1px solid #e6197f40;
    padding: 4px;
    text-align: center;
}

.mTable  tr:nth-child(even), .modal .modal-body table tr:nth-child(even){background-color: #f2f2f2;}

.mTable  th, .modal .modal-body table th {
    width: 33%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    border: 1px solid #e6197f40;
    text-align: center;
}

.mTable.quarter th, td {
    width: 25% !important;
}

.mTable span.end {
    margin-right: 33px;
    margin-top: -30px;
    position: absolute;
    right: 0;
}

@media (max-width: 992px) {
    .navbar-nav {
        max-width: 300px;
        text-align: center;
        margin: auto;
    }

    hr {
        height: 1px;
        width: 200px;
        background-color: var(--text-secondary);
        margin: 10px auto 20px auto;
        border: 0;
    }

    .item {
        margin-bottom: 15px;
    }
    
    nav .item.active {
        color: #1f1f1f !important;
    }
    
    nav .item.disabled {
        color: #6f6f6e99 !important;
    }

    a:hover {
        color: inherit !important;
    }

    h1 {
        font-size: 27px;
        text-align: center;
        padding: 0 2px;
    }

    h6 {
        font-family: 'Lato';
        margin: 0;
    }

    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        background-color: #ffffff4a !important;
    }
    .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
        background-color: #fff !important;
    }

    blockquote, p {
        text-align: justify;
        font-size: 13px;
    }

    ul.upl-6 {
        padding-left: 3rem!important;        
    }

    .pb-6 {
        padding-bottom: 6rem!important
    }

    .navbar-toggler {
        margin-right: 10px;
    }

    .navbar-brand {
        margin-left: 10px;
    }

    article>.container {
        padding-bottom: 20px;
    }

    #wrapper {
        padding: 0;
        padding-bottom: 15px;
    }

    .container main {
        padding-bottom: 100px;
    }

    .pl-5, .px-5 {
        padding-left: 1rem!important;
    }

    .pr-5, .px-5 {
        padding-right: 1rem!important;
    }

    .pt-5, .py-5 {
        padding-top: 1rem!important;
    }

    .pb-5, .py-5 {
        padding-bottom: 1rem!important;
    }

    .ml-5, .mx-5 {
        margin-left: 1rem!important;
    }

    .mr-5, .mx-5 {
        margin-right: 1rem!important;
    }

    .mt-5, .my-5 {
        margin-top: 1rem!important;
    }

    .mb-5, .my-5 {
        margin-bottom: 1rem!important;
    }

    .mb-4, .my-4 {
        margin-bottom: 10px!important;
    }

    .mmy-3 {
        margin-top: 50px;
    }

    .mb--1 {
        margin-bottom: -10px;
    }

    .mb--2 {
        margin-bottom: -20px;
    }

    .mt--1 {
        margin-top: -10px;
    }

    .mt--2 {
        margin-top: -20px;
    }

    h1 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .btn-pink {
        padding: 10px 15px;
    }

    .mt--3 {
        margin-top: -30px;
    }

    .pt-6 {
        padding-top: 2rem!important;
    }

    .pb-6 {
        padding-bottom: 2rem!important;
    }

    .modal {
        z-index: 9999;
    }

    .modal-header {
        padding: 10px;
    }

    .modal-header .h4 {
        font-size: 18px;
    }

    .modal .modal-body p {
        padding: 0;
    }

    .modal .modal-body {
        max-height: 300px;
        overflow: auto;
    }

    .modal .modal-body p {
        font-size: 14px;
    }

    .modal .modal-footer button {
        padding: 5px 18px;
        font-size: 14px;
    }

    .exercise .subtitle {
        font-size: 12px;
    }

    .col-sm-10 {
        max-width: 84%;
    }

    .col-sm-6 {
        max-width: 50%;
    }

    .col-sm-5 {
        max-width: 41.66%;
    }

    .col-sm-2 {
        max-width: 16%;
    }

    .col-sm-1 {
        max-width: 8.33%;
    }
}

.range-container {
    bottom: 2px;
}

.range-bar {
    width: 100%;
    height: 20px;
    border: 1px solid var(--secondary); 
    border-radius: 10px;
    overflow: hidden;
    background-color: #ececec;
    margin-left: 12px;
    margin-right: 12px;
}

.range-filled {
    height: 100%;
    background-color: var(--secondary);
}

.range-tooltip {
    margin-bottom: -35px;
    background-color: #f9d2e2;
    padding: 20px 20px 40px 20px;
    color: #404040;
    font-size: 13px;
    border-radius: 10px;
    margin-right: 12px;
    margin-left: 12px;
    display: none;
    position: absolute;
    bottom: 100%;
}

.range-point {
    width: 100%;
    height: 20px;
    position: relative;
}

.range-marker {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--secondary);
    cursor: pointer;
    border-radius: 6px;
    width: 20px;
}

.range-labels {
    margin-top: 10px;
    width: 100%;    
    font-size: 11px;
    line-height: 12px;
}

.range-labels div {
    width: 15%;
    display: inline-table;     
    padding-left: 5px;
    padding-right: 5px;
}

.row:hover > .range-tooltip, .row:active > .range-tooltip { 
    display: inline;
    transition: 1s all;
 }

 .row:hover > .range-bar, .row:active > .range-bar { 
    z-index: 9;
 }

/*
.range-point {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid var(--secondary);
    position: absolute;
    bottom: 25px;
    cursor: pointer;
    border-radius: 6px;
}
*/
.menu .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    display: block !important;
}