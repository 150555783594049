.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise7 .pink {
    color: white;
    background-color: var(--secondary);
    border-radius: 40px;
    padding: 10px 20px;
    max-width: 350px;
    margin: auto;
    min-height: 41px;
    font-size: 14px;
}


.exercise7 div.box {
    position: relative;
    width: 100%;    
}

.exercise7 div.box:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.exercise7 .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height:100%;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #ececec;
  }

  .flex-end {
      align-items: flex-end;
  }

  .flex-start {
      align-items: flex-start;
  }

  .flex-center {
      align-items: center;
  }

  .exercise7 h1 {
    font-size: 20px;    
    margin: 0;
    padding: 0;
  }

  .exercise7 .subtitle {
    font-size: 14px;    
  }

  .exercise7 h2 {
      color: var(--secondary) !important;
      font-size: 19px;
      margin: 0;
      padding: 0;
      font-weight: bold;
  }

  .exercise7 h3 {
      color: var(--text-primary) !important;
      font-size: 12px;
      margin: 0;
      padding: 0;
  }

.exercise7 .box div.header {
    min-height: 55px;
    max-width: 80%;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exercise7 .box div.title {
    min-height: 45px;
    max-width: 80%;
    margin:auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.exercise7 .box div.select {
    min-height: 100px;
    display: flex;
    padding: 10px;
}

.exercise7 .to-right {
    transform: translate(25px);
}

.exercise7 .to-left {
    transform: translate(-25px);
}

.top-left {
    border-radius: 100% 10px 10px 10px;
    -moz-border-radius: 100% 10px 10px 10px;
    -webkit-border-radius: 100% 10px 10px 10px;
}

.top-right {
    border-radius: 10px 100% 10px 10px;
    -moz-border-radius: 10px 100% 10px 10px;
    -webkit-border-radius: 10px 100% 10px 10px;
}

.bottom-left {
    border-radius: 10px 10px 10px 100%;
    -moz-border-radius: 10px 10px 10px 100%;
    -webkit-border-radius: 10px 10px 10px 100%;
}

.bottom-right {
    border-radius: 10px 10px 100% 10px;
    -moz-border-radius: 10px 10px 100% 10px;
    -webkit-border-radius: 10px 10px 100% 10px;
}

.exercise7 .select .icon {
    position: absolute;
    top: -14px;
    right: 7px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid;
    border-radius: 20px;
    color: var(--secondary);
}

.exercise7 .select .icon svg {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.exercise7 .box .title {
    text-transform: none !important;
}


@media (max-width: 992px) {
    .exercise7 .pink {
        max-width: 190px;
    }
    
    .exercise7 div.box:before {
        content: "";
        display: none;
    }

    .exercise7 .content {
        position: inherit;
    }

    .exercise7 .content > div {
        padding: 10px 0;
    }

    .exercise7 .box {
        height: 100%;
    }

    .exercise7 .box > div, .exercise7 .box .content > div {
        height: 100%;
    }

    .exercise7 .mrow .container >.row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .exercise7 h2 {
        font-size: 14px;
    }

    .exercise7 h3 {
        font-size: 11px;
    }

    .top-left {
        border-radius: 20px 10px 10px 10px;
        -moz-border-radius: 20px 10px 10px 10px;
        -webkit-border-radius: 20px 10px 10px 10px;
    }
    
    .top-right {
        border-radius: 10px 20px 10px 10px;
        -moz-border-radius: 10px 20px 10px 10px;
        -webkit-border-radius: 10px 20px 10px 10px;
    }
    
    .bottom-left {
        border-radius: 10px 10px 10px 20px;
        -moz-border-radius: 10px 10px 10px 20px;
        -webkit-border-radius: 10px 10px 10px 20px;
    }
    
    .bottom-right {
        border-radius: 10px 10px 20px 10px;
        -moz-border-radius: 10px 10px 20px 10px;
        -webkit-border-radius: 10px 10px 20px 10px;
    }

    

    .exercise7 .to-right {
        transform: none;
    }

    .exercise7 .to-left {
        transform: none;
    }
}