.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise>.mrow>div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
    text-align: center;
}

.exercise4 button {
    max-width: 200px;
    max-height: 50px;
    min-height: 50px;
    min-width: 150px;
    width: 100%;
    background-color: #e6197f !important;
    color: #fff !important;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    border: 0px;
}

.exercise4 img {
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
    cursor: pointer;
    border: 2px solid #d9d9d9 !important;
    border-radius: 20px;
}



@media (max-width: 992px) {
    .exercise4 .mrow div {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}