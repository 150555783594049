.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}
.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise11 h1 {
    margin: 10px 0 30px 0;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}

.exercise11 .block {
    background-color: #e6197f;
    margin: 15px 5px;
    text-align: center;
    color: white;
    padding: 20px 15px;
    border-radius: 30px;
    min-height: 130px;
    vertical-align: middle;
    display: flex;
}

.exercise11 h2 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}

.exercise11 span {
    font-size: 13px;
    margin: auto;
}

.exercise11 .answer {
    border: 2px solid #e6197f;
    margin: 15px 5px;
    text-align: center;
    padding: 20px 15px;
    border-radius: 30px;
    min-height: 130px;
    display: grid;
    vertical-align: middle;
    cursor: pointer;
    z-index: 999;
    position: relative;

}

.exercise11 .answer:hover, .exercise11 .answer:active {
    background-color: #fff7d9;
}

.exercise11 .answer:active {
    z-index: 9999999;
}

.exercise11 .react-draggable-dragging.answer {
    background-color: #fff7d9;
    opacity: 0.9;
}



.exercise11 .answer.goodanswer {
    background-color: #ffcc00;
}

.exercise11 .answer .icon {
    position: absolute;
    top: -14px;
    right: 7px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid;
    border-radius: 20px;
    color: #e6197f;
}

.exercise11 .answer .icon svg {
    width: 100%;
    height: 100%;
    padding: 5px;
}




@media (max-width: 992px) {   
    
    .exercise11 h1 {
        min-height: 50px;
        margin: 0;
    }
    .exercise11 .block, .exercise11 .answer {
        min-height: 200px;
    }
    
}