.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise>.mrow>div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
    text-align: center;
}

.exercise6 .question {
    color: white;
    background-color: var(--secondary);
    border-radius: 40px;
    padding: 20px;
}

.exercise6 .answer {
    color: var(--secondary);
    background-color: white;
    border-radius: 40px;
    padding: 20px;
    border: 2px var(--secondary) solid;
    cursor: pointer;
    font-weight: bold;
}

.exercise6 .answer:hover {
    background-color: #fff7d9;
}

.exercise6 .answer.bad {
    background-color: #c6c6c5;
}

.exercise6 .answer.correct {
    background-color: #ffcc00;
}

.exercise6 .answer .icon {
    position: absolute;
    top: -14px;
    right: 7px;
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid;
    border-radius: 20px;
}

.exercise6 .answer .icon svg {
    width: 100%;
    height: 100%;
    padding: 5px;
}

@media (max-width: 992px) {
    .exercise6 .question {
        margin: 0 10px 10px 10px;
        padding: 10px 20px;
    }
    
    .exercise6 .ml-20 {
        margin-left: 20px;
    }
    
    .exercise6 .mr-20 {
        margin-right: 20px;
    }

    .exercise6 .answer {
        padding: 10px;
    }

    .exercise6 .mtooltip {
        font-size: 13px;
    }

    .exercise6 .mrow {
        margin-top: 20px;
    }
}