.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise10 textarea {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0);
    border: 0;
    resize: none;
    text-align: center;
    vertical-align: middle;
}

.exercise10 .link {
    color: #e6197f;
    cursor: pointer;
    font-weight: bold;
}

.exercise10 textarea::placeholder {
  font-style:italic;
  color: #e6197f;
}