.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise>.mrow>div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
    text-align: center;
}

.exercise5 .col-lg-2 {
    line-height: 15px;
}

.exercise5 .col-lg-2 img {
    margin-top: -4px;
    max-width: 150px;
}

.exercise5 span {
    display: block;
}

.exercise5 .row.range {
    margin-bottom: 35px;
}

@media (max-width: 992px) {
    .exercise5 span {
        font-size: 12px;
    }

    .exercise5 .mrow.row {
        padding: 0 20px;
    }

    .exercise5 .range.row div {
        padding: 0;
    }

    .exercise5 .range-tooltip {
        padding: 10px 20px 20px 20px !important;
    }

    .exercise5 .picture {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 999999999;
        min-height: 100px;
        margin-bottom: 123px;
    }

    .exercise5 .range-labels .label {
        font-size: 9px;
    }
}