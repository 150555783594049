.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}
.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise12 span.white {
    background-color: white;
    padding: 3px 1px;
    line-height: 28px;
    margin: 0 3px;
}

.exercise12 span.white:hover {
    background-color: #ffcc00 !important;
}

.exercise12 span.white.good {
    background-color: #e6197f !important;
    color: white;
}

.exercise12 span.white.bad {
    background-color: lightgray !important;
}

.exercise12 .alert {
    position: absolute;
    bottom: -10%;
    width: 80%;
    padding: 30px 50px;
    background-color: #f9d2e2;
    left: 10%;
    border-radius: 30px;
    text-align: center;
    transition: 1s all;
}