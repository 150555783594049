.exercise {
    font-family: 'Lato';
    font-size: 15px;
    letter-spacing: 0.1px;
}

.exercise div {
    font-family: 'Lato';
    letter-spacing: 0.3px;
}

.exercise9 .block {
    width: 100%;
    margin-bottom: 10px;
    color: #3c3c3b;
    font-size: 12px;
    font-weight: bold;
}

.exercise9 .mrow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
}

.exercise9 .block div {
    border: 2px solid #e6197f;
    padding: 10px 10px;
    border-radius: 25px;
    height: 100%;
    width: 100%;
    text-align: center;
    display: table;
    cursor: pointer;
    transition: all 0.3s;
}

.exercise9 .block div:hover {
    background-color: #fff7d9;
}

.exercise9 .block.selected div {
    background-color: #ffcc00;
    transition: all 0.3s;
}

.exercise9 .block.good div {
    background-color: #d0ffd1;
    transition: all 0.3s;
}

.exercise9 .block.bad div {
    background-color: #ffd0d0;
    transition: all 0.3s;
}

.exercise9 .block div span {
    display: table-cell;
    vertical-align: middle;
}

.exercise9 button {
    max-width: 200px;
    max-height: 50px;
    min-height: 50px;
    min-width: 150px;
    width: 100%;
    background-color: #e6197f !important;
    color: #fff !important;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    border: 0px;
}

.exercise9 .answer {
    color: #e6197f;
    font-size: 16px;
}

.exercise9 .tryagain {
    cursor: pointer;
    border-bottom: 1px dotted #e6197f;
}

.exercise9 h1 {
    font-size: 15px;
    color: #e6197f !important;
    text-align: center;
    display: table-header-group;
}

@media (max-width: 992px) {    

    .exercise9 .mrow {
        padding: 0 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
    }

    .exercise9 .answer {
        font-size: 13px;
    }

    .exercise9 button.btn {
        font-size: 14px;
        padding: 5px 20px;
        min-height: 38px;
        width: 150px;
    }
}